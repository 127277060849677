<template>
  <div id="app">
    <link
      rel="stylesheet"
      href="https://cdn.jsdelivr.net/npm/vant@next/lib/index.css"
    />

    <!-- 引入 Vue 和 Vant 的 JS 文件 -->

    <router-view />
  </div>
</template>

<style>
html {
  background-color: #d2d2d20f;
}
</style>
