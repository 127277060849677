import request from "./axios";

export function dologin(params) {
  return request({
    url: "Auth/MobileLogin",
    method: "post",
    data: params,
  });
}

export function chageToken(params) {
  return request({
    url: "Auth/ChargeToken",
    method: "get",
    params,
  });
}
export function NoticePageQuery(params) {
  return request({
    url: 'ScanOrder/GetNotice',
    method: 'get',
    params,
  });
}
//获取公告显示时长
export function SystemConfigGetConfig(params) {
  return request({
    url: 'SystemConfig/GetConfig',
    method: 'get',
    params,
  });
}

//扫描
export function doScan(params) {
  return request({
    url: "ProductionCenter/Scan",
    method: "get",
    params,
  });
}

//工作记录
export function doworkRecord(params) {
  return request({
    url: "PersonalCenter/GetDayRecord",
    method: "get",
    params,
  });
}

//薪资统计
export function dosalaryStatistics(params) {
  return request({
    url: "PersonalCenter/GetMonthSalary",
    method: "get",
    params,
  });
}

//每日薪资
export function dodailySalary(params) {
  return request({
    url: "PersonalCenter/GetDaySalary",
    method: "get",
    params,
  });
}
//投诉建议图片上传
export function doUpload(data) {
  return request({
    url: "Suggestion/Upload",
    method: "post",
    data,
  });
}
//投诉建议提交
export function addSuggestion(data) {
  return request({
    url: "Suggestion/AddSuggestion",
    method: "post",
    data,
  });
}
//详情
export function getOrderView(params) {
  return request({
    url: "PersonalCenter/GetOrderView",
    method: "get",
    params,
  });
}
//申请体验
export function application(data) {
  return request({
    url: "ApplicationExperience/AddApplicationExperience",
    method: "post",
    data,
  });
}
//刷新
export function getUserWork(params) {
  return request({
    url: "PersonalCenter/GetUserWork",
    method: "get",
    params,
  });
}
//是否开启扫描提示
export function getScanTipsState(params) {
  return request({
    url: "ClientSystemParameter/GetScanTipsState",
    method: "get",
    params,
  });
}
//是否开启工作量展示
export function GetWorkloadDisplay(params) {
  return request({
    url: "ClientSystemParameter/GetWorkloadDisplay",
    method: "get",
    params,
  });
}

