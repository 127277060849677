import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import axios from "axios";
import { Popup } from "vant";
import { Overlay } from "vant";
// import Vant from "vant";
// import "vant/lib/index.css";
import "./config/axios";
const app = createApp(App);
app.use(router);
app.use(Popup);
app.use(Overlay);

// app.use(Vant);
app.config.globalProperties.$http = axios;
app.mount("#app");
