import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
  {
    name: "notFound",
    path: "/:path(.*)+",
    redirect: "/login",
    meta: {
      title: "登录",
    },
  },
  {
    name: "login",
    path: "/login",
    component: () => import("./view/login"),
    meta: {
      title: "登录",
    },
  },
  {
    name: "experience",
    path: "/experience",
    component: () => import("./view/experience"),
    meta: {
      title: "申请体验",
    },
  },
  {
    name: "tab",
    path: "/tab",
    component: () => import("./view/tabber"),
    redirect: "/production",
    children: [
      {
        name: "production",
        path: "/production",
        component: () => import("./view/production"),
        redirect: "/productionMain",
        children: [
          {
            name: "productionMain",
            path: "/productionMain",
            component: () => import("./view/productionMain"),
            meta: {
              title: "生产中心-一条马ERP",
            },
          },
          {
            name: "workRecord",
            path: "/workRecord",
            component: () => import("./view/ninePalaces/workRecord"),
            meta: {
              title: "工作记录-一条马ERP",
            },
          },
          {
            name: "salaryStatistics",
            path: "/salaryStatistics",
            component: () => import("./view/ninePalaces/salaryStatistics"),
            meta: {
              title: "薪资统计-一条马ERP",
            },
          },
          {
            name: "dailySalary",
            path: "/dailySalary",
            component: () => import("./view/ninePalaces/dailySalary"),
            meta: {
              title: "每日薪资-一条马ERP",
            },
          },
        ],
      },
      {
        name: "personal",
        path: "/personal",
        component: () => import("./view/personal"),
        meta: {
          title: "个人中心-一条马ERP",
        },
      },
      {
        name: "complaintsSuggestions",
        path: "/complaintsSuggestions",
        component: () => import("./view/complaintsSuggestions"),
        meta: {
          title: "投诉建议-一条马ERP",
        },
      },
    ],
  },

  {
    name: "mobilePhoneApp",
    path: "/mobilePhoneApp",
    component: () => import("./view/mobilePhoneApp"),
    meta: {
      title: "一条马ERP手机APP下载-一条马ERP",
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});
router.beforeEach((to, form, next) => {
  if (to.path === "/login") return next();
  // const tokenStr = localStorage.getItem("token");
  // if (!tokenStr && to.path !== "/login") {
  //   return next("/login");
  // }

  next();
});
export { router };
