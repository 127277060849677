import axios from "axios";
import { chageToken } from "./_api";
import { router } from "@/router";
//https://api.erp.yitiaoma.com/api/admin/
//http://localhost:64311/api/admin/
const instance = axios.create({
  baseURL: "https://api.erp.yitiaoma.com/api/admin/",
  // https://tapi.erp.yeahwaysoft.com.cn/api/admin/
  //https://api.erp.yitiaoma.com/api/admin/
  timeout: 1000000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    DevicePort: 2,
  },
});

instance.interceptors.request.use(
  function(config) {
    // 在发送请求之前做些什么
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    config.headers["DeviceId"] = encodeURIComponent(
      localStorage.getItem("deviceId")
    );
    config.headers["DeviceName"] = encodeURIComponent(
      `${localStorage.getItem("deviceName")} ${localStorage.getItem(
        "versioncode"
      )}`
    );

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function(response) {
    return response;
  },
  async function(error) {
    let { data } = await chageToken({ deviceType: 3 });
    console.log(data);
    if (data && data.code == 1) {
      let { token, userInfo, wages, workload } = await data.data;
      console.log("waweaweawe");
      localStorage.setItem("token", token);
      localStorage.setItem("userData", JSON.stringify(userInfo));
      localStorage.setItem("wages", wages.toFixed(2));
      localStorage.setItem("workload", workload);
      localStorage.setItem("announcement", 1);
      router.go(0);
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("userData");
      router.push("/login");
    }
    return Promise.reject(error);
  }
);

export default instance;
